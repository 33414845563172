var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "label",
      { staticClass: "label d-block", attrs: { for: _vm.componentID } },
      [_vm._v("\n\t\t" + _vm._s(_vm.rendLabel) + "\n\t")]
    ),
    !_vm.inlineValidator
      ? _c(
          "div",
          [
            _c("v-select", {
              ref: "google-auto-complete",
              attrs: {
                id: _vm.componentID,
                placeholder: _vm.rendPlaceholder,
                options: _vm.resultsListMap,
              },
              on: { input: _vm.getDetailsOfLocation, search: _vm.handleSearch },
              scopedSlots: _vm._u(
                [
                  {
                    key: "no-options",
                    fn: function ({}) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.FormMSG(2, "Please type a location")) +
                            "\n\t\t\t"
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3321790196
              ),
              model: {
                value: _vm.locationModel,
                callback: function ($$v) {
                  _vm.locationModel = $$v
                },
                expression: "locationModel",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "v-select-validator-wrapper" },
          [
            _c("v-select", {
              ref: "google-auto-complete",
              class: {
                "is-invalid": _vm.isSubmitted && _vm.$v.locationModel.$error,
              },
              attrs: {
                id: _vm.componentID,
                placeholder: _vm.rendPlaceholder,
                options: _vm.resultsListMap,
              },
              on: { input: _vm.getDetailsOfLocation, search: _vm.handleSearch },
              model: {
                value: _vm.$v.locationModel.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.$v.locationModel, "$model", $$v)
                },
                expression: "$v.locationModel.$model",
              },
            }),
            _vm.isSubmitted && !_vm.$v.locationModel.required
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("\n\t\t\t" + _vm._s(_vm.errorMessage) + "\n\t\t"),
                ])
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }