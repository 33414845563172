<template>
	<div class="form-group">
		<label class="label d-block" :for="componentID">
			{{ rendLabel }}
		</label>
		<div v-if="!inlineValidator">
			<v-select
				:id="componentID"
				ref="google-auto-complete"
				v-model="locationModel"
				:placeholder="rendPlaceholder"
				:options="resultsListMap"
				@input="getDetailsOfLocation"
				@search="handleSearch"
			>
				<template #no-options="{}"
					><!-- search, searching, loading -->
					{{ FormMSG(2, 'Please type a location') }}
				</template>
			</v-select>
		</div>
		<div class="v-select-validator-wrapper" v-else>
			<v-select
				:id="componentID"
				ref="google-auto-complete"
				v-model="$v.locationModel.$model"
				:placeholder="rendPlaceholder"
				:options="resultsListMap"
				@input="getDetailsOfLocation"
				@search="handleSearch"
				:class="{ 'is-invalid': isSubmitted && $v.locationModel.$error }"
			>
			</v-select>
			<div v-if="isSubmitted && !$v.locationModel.required" class="invalid-feedback">
				{{ errorMessage }}
			</div>
		</div>
	</div>
</template>

<script>
import loadJS from 'load-js';
import { isNil, makeID } from '@/shared/utils';
import { convertIsoToKey } from '@/shared/helpers';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

let loadModulePromise;
const loadModule = (options) => {
	if (Object.prototype.hasOwnProperty.call(window, 'google')) {
		return Promise.resolve();
	}
	const opt = { libraries: 'places', ...options };
	const parameters = Object.keys(opt)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(opt[key])}`)
		.join('&');
	let url = `https://maps.googleapis.com/maps/api/js?${parameters}`;
	return loadJS(url).catch((e) => {
		loadModulePromise = null;
		console.warn('Error loading google maps script', e);
	});
};

export default {
	name: 'AutoCompleteGoogleLocation',
	mixins: [languageMessages, validationMixin],
	props: {
		value: {
			type: String,
			required: false,
			default: null
		},
		label: {
			type: String,
			required: false,
			default: null
		},
		placeholder: {
			type: String,
			required: false,
			default: null
		},
		version: {
			type: String,
			required: false
		},
		addressFields: {
			type: Object,
			required: false,
			default: () => {}
		},
		types: {
			type: [String, Array],
			required: false,
			default: null
		},
		country: {
			type: [String, Array],
			required: false,
			default: null
		},
		enableGeocode: {
			type: Boolean,
			required: false,
			default: false
		},
		/**
		 * gonna force a new search from parent's component
		 */
		putSearch: {
			type: String,
			required: false,
			default: null
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		errorMessage: {
			type: String,
			required: false,
			default: null
		},
		inlineValidator: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			location: '',
			locationSelected: '',
			googleService: null,
			hasDownBeenPressed: false,
			currentPlace: null,
			resultsList: [],
			dropDownListOpen: false,
			locationModel: null,
			parsedAddressFields: {}
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendLabel() {
			return isNil(this.label) ? this.FormMSG(2897, 'Find an address') : this.label;
		},
		/**
		 * @return {String}
		 */
		componentID() {
			return `_autocomplete__${makeID(10)}`;
		},
		rendClass() {
			return { 'vs--open': this.dropDownListOpen };
		},
		resultsListMap() {
			return this.resultsList.map((i) => ({
				label: i.description,
				code: i.place_id
			}));
		},
		rendPlaceholder() {
			const t = !isNil(this.placeholder) ? this.placeholder : this.FormMSG(2, 'Search for a location or a place');
			return isNil(this.value) ? t : this.value;
		}
	},
	watch: {
		types(newVal) {
			if (newVal) {
				const types = Array.isArray(newVal) ? newVal : [newVal];
				this.googleService.setTypes(types);
			}
		},
		resultsList(list) {
			this.dropDownListOpen = list.length > 0;
		},
		/**
		 * @param {String or null} search
		 */
		putSearch(search) {
			// console.log('search, going to put immediate auto search')
			this.setImmediateAutoSearch(search);
		},
		isSubmitted: {
			handler(n) {
				if (!isNil(n) && n === true) {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.$emit('google-location:selector:invalid', true);
					} else {
						this.$emit('google-location:selector:invalid', false);
					}
				}
			},
			deep: true,
			immediate: true
		},
		value: {
			handler(n) {
				if (!isNil(n)) {
					this.locationModel = n;
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		const apiKey = process.env.VUE_APP_GOOLGE_API_KEY;
		// STUB for vue2-google-maps and vue-google-places work together
		// TODO chanhe this to @google/map module in future
		if (typeof this.$gmapApiPromiseLazy === 'function') {
			loadModulePromise = this.$gmapApiPromiseLazy();
		} else {
			loadModulePromise =
				loadModulePromise ||
				loadModule({
					key: apiKey,
					v: this.version
				});
		}

		this.parsedAddressFields = Object.assign(
			{
				street_number: 'short_name',
				route: 'long_name',
				locality: 'long_name',
				administrative_area_level_1: 'short_name',
				administrative_area_level_2: 'short_name',
				administrative_area_level_3: 'short_name',
				postal_code: 'short_name'
			},
			this.addressFields
		);
	},
	mounted() {
		loadModulePromise
			.then(() => this.setupGoogle())
			.then(() => {
				if (this.putSearch) {
					this.setImmediateAutoSearch(this.putSearch);
				}
			});
	},
	methods: {
		setupGoogle() {
			const el = this.$refs.search;
			const options = {};

			if (typeof this.types === 'string') {
				options.types = [this.types];
			} else if (Array.isArray(this.types)) {
				options.types = this.types;
			}

			// if (this.country) options.componentRestrictions.country = this.country;

			this.googleService = new google.maps.places.AutocompleteService(el);
			this.geocoder = new google.maps.Geocoder();
			this.geolocate();
		},
		displaySuggestions(predictions, status) {
			if (status != google.maps.places.PlacesServiceStatus.OK) return;
			this.resultsList = predictions;
		},
		getDetailsOfLocation(prediction) {
			const map = new google.maps.Map(document.createElement('div'));
			const gp = new google.maps.places.PlacesService(map);
			if (!isNil(prediction)) {
				gp.getDetails({ placeId: prediction.code }, (place, status) => {
					if (status !== 'OK') return;
					// this.locationSelected = prediction.labe;
					this.handlePlaceSelected(place);
				});
			}
		},
		/**
		 * @param {Object} place
		 * @return {Event}
		 */
		handlePlaceSelected(place) {
			const parcedPlace = this.parsePlace(place);
			this.$emit('input', parcedPlace.name);
			this.$emit('placechanged', parcedPlace);
		},
		handleSearch(search) {
			if (isNil(search) || search === '') {
				this.resultsList = [];

				return;
			}
			const options = {
				input: search,
				fields: ['name', 'geometry.location', 'place_id', 'formatted_address'],
				componentRestrictions: { country: this.country }
			};
			// this.googleService.getQueryPredictions(options, this.displaySuggestions);
			this.googleService.getPlacePredictions(options, this.displaySuggestions);
		},
		handleKeydown(e) {
			if (e.keyCode === 40) this.hasDownBeenPressed = true;
		},
		/**
		 * @param {Object} place
		 * @return {Object}
		 */
		parsePlace(place) {
			const returnData = {};

			if (!isNil(place.address_components)) {
				// Get each component of the address from the place details
				for (let i = 0; i < place.address_components.length; i += 1) {
					const addressType = place.address_components[i].types[0];

					if (this.parsedAddressFields[addressType]) {
						const val = place.address_components[i][this.parsedAddressFields[addressType]];
						returnData[addressType] = val;
					}
					if (addressType === 'country') {
						returnData.country = place.address_components[i]['long_name'];
						returnData.country_code = place.address_components[i]['short_name'];
						returnData.country_key = convertIsoToKey(returnData.country_code);
					}
				}

				returnData.latitude = place.geometry.location.lat();
				returnData.longitude = place.geometry.location.lng();

				// additional fields available in google places results
				returnData.name = place.name;
				returnData.formatted_address = place.formatted_address;
				returnData.photos = place.photos;
				returnData.place_id = place.place_id;
				returnData.place = place;
			}

			return returnData;
		},
		geolocate() {
			if (this.enableGeolocation && !this.geolocateSet) {
				if (!navigator.geolocation) {
					return;
				}
				navigator.geolocation.getCurrentPosition((position) => {
					const geolocation = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					const circle = new window.google.maps.Circle({
						center: geolocation,
						radius: position.coords.accuracy
					});
					if (this.enableGeocode) {
						this.geocoder.geocode({ location: geolocation }, (results, status) => {
							if (status === 'OK' && results.length) {
								this.textValue = results[1].formatted_address;
								const pl = this.parsePlace(results[1]);
								this.changePlace(pl);
							}
						});
					}
					this.googleService.setBounds(circle.getBounds());
					this.geolocateSet = true;
				});
			}
		},
		/**
		 * @param {String} search
		 */
		async setImmediateAutoSearch(search) {
			if (isNil(search)) return;
			const options = {
				input: search,
				fields: ['name', 'geometry.location', 'place_id', 'formatted_address'],
				componentRestrictions: { country: this.country }
			};
			// this.googleService.getQueryPredictions(options, this.displaySuggestions);
			this.googleService.getPlacePredictions(options, (predictions, status) => {
				if (status != google.maps.places.PlacesServiceStatus.OK) return;
				const _P = predictions[0];
				if (isNil(_P)) return;
				this.getDetailsOfLocation({ code: _P.place_id, label: _P.description });
				this.locationModel = search;
			});
		},
		clearSelected() {
			this.locationModel = null;
		}
	},
	validations: {
		locationModel: {
			required
		}
	}
};
</script>
