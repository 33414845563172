<template>
	<div class="form">
		<b-row>
			<b-col v-if="filterableByCountry" md="3">
				<b-form-group :label="FormMSG(122332, 'Country')" label-for="supplier-country-filter">
					<CountrySelector
						v-model="addressForm.countryKey"
						custom-id="supplier-country-filter"
						@change="handleCountryChange"
						:country-selected="addressForm.countryKey"
						:error="isSubmitted && $v.addressForm.countryKey.$error"
					/>
				</b-form-group>
			</b-col>
			<b-col>
				<GoogleAutoComplete ref="address" :put-search="fillSearch" :country.sync="googleRestrictedCountry" @placechanged="onPlaceChanged" />
				<!-- <p>{{ `${fillSearch} in Address.vue` }}</p> -->
				<!--  😡 -->
			</b-col>
			<b-col v-if="showClearAddressBtn" md="1">
				<b-button style="margin-top: 24px; width: 100%" :disabled="isClearBtnDisabled" @click="handleClearAddress">
					{{ FormMSG(2678678, 'Clear') }}
				</b-button>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="5">
				<b-form-group id="input-group-5" :label="FormMSG(5, 'State')" label-for="input-5">
					<b-form-input
						id="input-5"
						v-model="addressForm.state"
						:placeholder="FormMSG(22, 'State...')"
						:class="{
							'is-invalid': isSubmitted && $v.addressForm.state.$error
						}"
					/>
					<div v-if="isSubmitted && $v.addressForm.state.$error" class="invalid-feedback">
						{{ FormMSG(65, 'Please, length should be at least 2') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="5">
				<b-form-group id="input-group-6" :label="FormMSG(6, 'City')" label-for="input-6">
					<b-form-input
						id="input-6"
						v-model="addressForm.city"
						:placeholder="FormMSG(23, 'City...')"
						:class="{
							'is-invalid': isSubmitted && $v.addressForm.city.$error
						}"
					/>
					<div v-if="isSubmitted && $v.addressForm.city.$error" class="invalid-feedback">
						{{ FormMSG(65, 'Please, length should be at least 2') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="2">
				<b-form-group id="input-group-4" :label="FormMSG(4, 'Zip')" label-for="input-4">
					<b-form-input
						id="input-4"
						v-model="addressForm.zip"
						:placeholder="FormMSG(21, 'Zip...')"
						:class="{
							'is-invalid': isSubmitted && $v.addressForm.zip.$error
						}"
					/>
					<div v-if="isSubmitted && $v.addressForm.zip.$error" class="invalid-feedback">
						{{ FormMSG(66, 'Please, enter a right zip code.') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="7">
				<b-form-group id="input-group-1" :label="FormMSG(1, 'Street')" label-for="input-1">
					<b-form-input id="input-1" v-model="addressForm.street" :placeholder="FormMSG(18, 'Street...')" />
				</b-form-group>
			</b-col>
			<b-col sm="2">
				<b-form-group id="input-group-2" :label="FormMSG(2, 'Number')" label-for="input-2">
					<b-form-input id="input-2" v-model="addressForm.number" :placeholder="FormMSG(19, 'Number...')" />
				</b-form-group>
			</b-col>
			<b-col sm="3">
				<b-form-group id="input-group-3" :label="FormMSG(3, 'Box')" label-for="input-3">
					<b-form-input id="input-3" v-model="addressForm.box" :placeholder="FormMSG(20, 'Box...')" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-group id="input-group-7" :label="FormMSG(7, 'Country')" label-for="input-7">
					<b-form-input id="input-7" v-model="addressForm.country" disabled :placeholder="FormMSG(24, 'Country ...')"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group id="input-group-17" :label="FormMSG(17, 'Country code')" label-for="input-17">
					<b-form-input id="input-17" v-model="addressForm.countryCode" disabled :placeholder="FormMSG(25, 'Code ...')"></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="5">
				<b-form-group id="input-group-8" :label="FormMSG(8, 'Latitude')" label-for="input-8">
					<b-form-input id="input-8" v-model="addressForm.lat" />
				</b-form-group>
			</b-col>
			<b-col sm="5">
				<b-form-group id="input-group-9" :label="FormMSG(9, 'Longitude')" label-for="input-9">
					<b-form-input id="input-9" v-model="addressForm.lng" />
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-center">
				<b-form-group :label="FormMSG(10, 'Map')" label-for="input-10">
					<a :href="rendGoogleMapLink" target="_blank" style="width: 100%" class="btn btn-primary px-1">
						<i class="icon-location-pin" />
					</a>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import mapProps from '@/shared/vuePropsMapper';
import { isNil, getObjectFromValue, zipCodeValid } from '@/shared/utils';
import { mapFilters } from '~filters';
import { convertIsoToKey } from '@/shared/helpers.js';
import LanguageMessages from '@/mixins/languageMessages';
import CountrySelector from '@/components/Selectors/CountrySelector';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import { store } from '@/store';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';

const defaultAddressForm = {
	street: '',
	number: '',
	box: '',
	zip: '',
	state: '',
	city: '',
	countryCode: '',
	lat: '',
	lng: ''
};

export default {
	name: 'AddressFormComponent',
	components: { GoogleAutoComplete, CountrySelector },
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		...mapProps(['editData', 'autoCompletedData', 'formValidation'], {
			type: Object,
			required: false,
			default: null
		}),
		...mapProps(['hasCountryKeyParentFilter', 'showClearAddressBtn'], {
			type: Boolean,
			required: false,
			default: false
		}),
		fillSearch: {
			// make search from parent's
			type: String,
			required: false,
			default: null
		},
		filterableByCountry: {
			type: Boolean,
			required: false,
			default: true
		},
		countryKey: {
			type: Number,
			required: false,
			default: 16965 // Belgium's countryKey
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			address: '',
			addressForm: {},
			googleRestrictedCountry: 'BE',
			countryKeyFilter: 16965
		};
	},
	computed: {
		...mapGetters({
			focusSupplier: 'suppliers/focusSupplier'
		}),
		optionsForCountries() {
			return this.FormMenu(8);
		},
		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		isEditMode() {
			return !isNil(this.editData);
		},
		isClearBtnDisabled() {
			return this.addressForm === defaultAddressForm;
		},
		googleAutocomplete() {
			return this.$refs.googleAutocomplete;
		},
		/**
		 * @return {String}
		 */
		rendGoogleMapLink() {
			const { lat, lng } = this.addressForm;
			if (isNil(lat) || isNil(lng)) return '';
			return `http://www.google.com/maps/place/${lat},${lng}`;
		}
	},
	watch: {
		/**
		 * @param {Number} key
		 */
		countryKey: {
			handler(key) {
				if (isNil(key)) return;
				this.addressForm.countryKey = key;
				const iso = this.getCountryIsoFromKey(key);
				this.addressForm.countryCode = iso.toUpperCase();
				this.googleRestrictedCountry = iso;
				this.addressForm.country = this.getCountryNameFromKey(key);
			},
			deep: true,
			immediate: true
		},
		addressForm: {
			handler(address) {
				this.$v.$touch();
				if (this.$v.$invalid) {
					this.$emit('invalid:form', true);
				} else {
					this.$emit('invalid:form', false);
					this.$emit('change', address);
				}
			},
			deep: true
		},
		autoCompletedData: {
			/**
			 * @param {Object} address
			 */
			handler(address) {
				if (isNil(address)) return;

				const merg = Object.assign({}, this.addressForm, address);
				this.addressForm = this.filterableByCountry ? omit(['countryKey', 'countryCode', 'country'], merg) : merg;
				// this.addressForm = {
				// 	...this.addressForm,
				// 	...address
				//}
			},
			deep: true
		},
		optionsForCountryCode: {
			handler() {
				this.initDefaultRestrictedCountry();

				if (this.addressForm.country === '') {
					this.addressForm.country = this.getCountryNameFromKey(this.countryKey);
					this.addressForm.countryCode = this.getCountryIsoFromKey(this.countryKey).toUpperCase();
				}
			},
			deep: true
		}
	},
	async created() {
		this.initDefaultRestrictedCountry();
		this.initDefaultCountryKey();
		await this.initAddress();
		this.initFilteredCountry();
	},
	methods: {
		...mapFilters(['capitalize']),
		autoFocusAddressInput() {
			if (isNil(this.$refs.address)) return;
			this.$refs.address.focus();
		},
		initAddress() {
			if (!this.isEditMode) return;
			const merg = this.hasCountryKeyParentFilter
				? {
						country: this.getCountryNameFromKey(this.countryKey),
						countryCode: this.getCountryIsoFromKey(this.countryKey).toUpperCase()
				  }
				: {};
			this.addressForm = {
				...this.addressForm,
				...this.editData,
				...merg
			};
		},
		initDefaultRestrictedCountry() {
			if (!this.filterableByCountry) return;
			const key = this.addressForm.countryKey;
			this.googleRestrictedCountry = this.getCountryIsoFromKey(key);
		},
		async initDefaultCountryKey() {
			const project = store.state.myProject;
			/**
			 * @TODO
			 * Filter legalEntity whith "isOwner" when it's became more than one
			 */
			const legalEntity = project.legalEntities.length > 0 ? project.legalEntities[0] : null;

			if (isNil(store.state) || (!isNil(legalEntity) && legalEntity.addressId === 0)) {
				// if no legal entity defined or no address of the legal entity return
				return;
			}

			const countryKey =
				!isNil(legalEntity) && !isNil(legalEntity.address) && legalEntity.address.countryKey > 0
					? legalEntity.address.countryKey
					: this.countryKeyFilter;
			this.addressForm.countryKey = countryKey;
		},
		initFilteredCountry() {
			if (!this.hasCountryKeyParentFilter) return;
			this.googleRestrictedCountry = this.getCountryIsoFromKey(this.countryKey);
			this.addressForm.countryKey = this.countryKey;
		},
		/**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 */
		onPlaceChanged(addressData) {
			this.addressForm = {
				street: addressData.route || addressData.place.name,
				city: addressData.locality || addressData.administrative_area_level_2,
				number: addressData.street_number,
				state: addressData.administrative_area_level_1,
				zip: addressData.postal_code,
				country: addressData.country,
				countryCode: addressData.country_code,
				countryKey: convertIsoToKey(addressData.country_code),
				lat: addressData.latitude,
				lng: addressData.longitude
			};

			this.$emit('address-maplink-change', addressData.place.url);
		},
		getCountryIsoFromKey(key) {
			const country = getObjectFromValue(this.optionsForCountryCode, 'value', key);
			return country ? country.text.toLowerCase() : '';
		},
		getCountryNameFromKey(key) {
			const country = getObjectFromValue(this.optionsForCountries, 'value', key);
			return country ? country.text : '';
		},
		handleAutocompleteAddressError(error) {
			console.log({ error });
		},
		/**
		 * @param {Object} country
		 */
		handleCountryChange(country) {
			this.addressForm.countryKey = country.key.value;
			this.googleRestrictedCountry = this.getCountryIsoFromKey(this.addressForm.countryKey);
		},
		getCountryIsoFromKey(key = 16965) {
			const country = getObjectFromValue(this.optionsForCountryCode, 'value', key);
			return country ? country.text.toLowerCase() : '';
		},
		handleClearAddress() {
			this.addressForm = Object.assign({}, defaultAddressForm);
			this.initDefaultCountryKey();
		},
		clearData() {
			this.addressForm = {
				lat: 0,
				lng: 0
			};
			this.$refs['address'].clearSelected();
		}
	},
	validations() {
		return {
			addressForm: {
				countryKey: {
					required,
					min: minLength(2)
				},
				state: {
					required,
					min: minLength(2)
				},
				city: {
					required,
					min: minLength(2)
				},
				zip: {
					required,
					min: minLength(2)
				}
			}
		};
	}
};
</script>
